/* Global CSS */

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
.cursor-pointer {
  cursor: pointer;
}
:root {
  --primary: #5e3bee;
  --heading-color: #282938;
  --bg-shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* About Me */
.section--title {
  color: var(--heading-color);
  font-size: 34px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}

/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 80px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.navbar--items ul > li > a {
  text-decoration: none;
}
/* Navbar Content */
.navbar--content {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}
.navbar--active-content {
  color: var(--primary);
}
.language-parent {
  margin-right: 25px;
  position: relative;
}
.lang-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  img {
    width: 40px;
  }
}
.language-modal {
  position: absolute;
  top: 37px;
  left: -20px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-top-right-radius: 0;
  padding: 10px;
  button {
    height: 50%;
    background: transparent;
    font-weight: 800;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      transform: scale(1.1);
      background-color: rgba(200, 200, 200, 0.5);
      transition: all, 0.2s;
    }
    img {
      width: 50px;
      display: inline;
    }
  }
}
/* Navbar Styles Ends */

/* Hero Section Style */
.hero--section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5rem;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}
.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}
.hero--section--content--box > button {
  margin-top: 21.333px;
}
.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}
.hero--section--title--color {
  color: var(--primary);
}
.hero--section-description {
  color: var(--darkblue);
  font-size: 21px;
  font-weight: 400;
  line-height: 36px;
}
.hero--section--img {
  display: flex;
}
.hero--section--img > img {
  width: 100%;
  height: 100%;
}
/* Hero Section Style Ends */

/* About Us */
.experience--section {
  display: grid;
  padding: 5rem;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}
.experience--section--img > img {
  width: 100%;
  height: 100%;
}
td {
  padding: 10px;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
/* About Us Ends */
/* Experience Starts */
// scss
.about--section {
  padding: 5rem;
  overflow: hidden;
  text-align: center;
  h1 {
    margin-bottom: 3rem;
  }
  &-container {
    display: flex;
    gap: 4rem;
    &-left {
      display: flex;
      width: 400px;
      height: 400px;
      margin: auto 0;
      img {
        border-radius: 2rem;
      }
    }
    &-right {
      width: 100%;
      &-boxes {
        display: flex;
        gap: 2rem;
        margin: 2rem 0;
        .experience-box {
          width: 100%;
          border: 1px solid;
          border-radius: 2rem;
          padding: 1.5rem;
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }
}
/* Experience Ends */

/* Footer Start     */
.footer--container {
  display: flex;
  padding: 2rem 5rem;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
  margin-top: 3rem;
}
.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.footer--link--container .logo {
  width: 220px;
}
.box {
  width: 400px;
  height: 230px;
  overflow: hidden;
}
.box img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.divider {
  margin: 2rem 0;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Hamburger menu  */
.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}
.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
  }
}

@media only screen and (max-width: 1600px) {
}
@media screen and (max-width: 1200px) {
  .experience--section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .about--section {
    height: fit-content;
    &-container {
      display: block;
      &-left {
        width: auto;
        height: 46vw;
        justify-content: center;
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
  }
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .hero--section--title,
  .sections--heading {
    font-size: 30px;
    line-height: 40px;
  }
  .hero--section--content--box {
    gap: 10px;
    text-align: center;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
  .btn-outline-primary {
    display: none;
  }
  .language-parent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 780px) {
  .hero--section {
    padding: 3rem 0;
  }

  .experience--section {
    padding: 2rem;
  }
  .navbar {
    padding: 15px 50px;
  }
  .footer--container {
    padding: 3rem 2rem;
    gap: 0;
  }
  .footer--items > ul {
    gap: 1rem;
  }
  .footer--link--container .logo {
    width: 150px;
  }
  .sections--heading {
    font-size: 30px;
  }
  .hero--section-description {
    font-size: 21px;
  }
  .experience--section--img > img {
    width: 350px;
  }
  .box {
    padding: 1rem;
  }
  .box img {
    object-fit: contain;
  }
  .nav__hamburger {
    top: 34px;
  }
}
@media screen and (max-width: 600px) {
  .about--section {
    padding: 3rem 2rem;
    &-container-right-boxes {
      overflow: hidden;
      padding: 2rem;
      flex-direction: column;
      .experience-box {
        width: auto;
      }
    }
  }
  .language-parent {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
}
/* Responsive Screens Ends*/
